import { FC, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUtility, setLanguage } from "../../store/reducers/utilitySlice";

const ChangeLanguageStyle = styled.div`
    span {
        font-family: 'Cairo', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        color: #00b0f0;
    }
`;

interface IProps {
  className?: string;
}

const ChangeLanguage: FC<IProps> = (props) => {
  const { className } = props;
  const { i18n } = useTranslation();
  const { lang } = useSelector(selectUtility);
  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
  }, [lang]);
  return (
    <ChangeLanguageStyle className={className}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(setLanguage(i18n.language === "en" ? "ar" : "en"));
              }}
            >
                {i18n.language === "en" ? "عربي" : "English"}
            </span>
    </ChangeLanguageStyle>
  );
};
export default ChangeLanguage;
