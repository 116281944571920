import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { FieldProps } from 'formik';

const UploadFileStyle = styled.div`
    width: 100%;

    input {
        display: none;
    }

    label {
        cursor: pointer;
        font-family: 'Neue Helvetica';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: white;
        padding: 15px 25px;
        background-color: #003399;
        border-radius: 10px;
        display: block;
        width: 100%;
        text-align: center;
    }
`;
interface IProps extends FieldProps {
    text: string;
    name: string;
    className?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}
const UploadFile: FC<IProps> = (props) => {
    const {
        text,
        name,
        className,
        onChange,
        field,
        form: { setFieldValue },
    } = props;

    return (
        <UploadFileStyle className={className}>
            <input
                type="file"
                name={field.name}
                id={field.name}
                accept="image/png, image/jpeg"
                onChange={(event) => {
                    if (onChange && event.currentTarget.files) {
                        const formData = new FormData();
                        formData.append('file', event.currentTarget.files[0]);
                        setFieldValue(field.name, formData);
                        onChange(event);
                    }
                }}
            />
            <label htmlFor={field.name}>{text}</label>
        </UploadFileStyle>
    );
};

export default UploadFile;
