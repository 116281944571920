import React from 'react';
import styled from 'styled-components';

interface IProps {
    marginRight: number;
}
export const Checkbox = styled.input<IProps>`
    margin-right: ${(props) => props.marginRight}px;
    appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 3px;
    background-color: #d9d9d9;
    position: relative;
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    &:checked {
        background-color: #003399;
    }
`;
