import React, { FC, useEffect, useState } from "react";
import NextQuestionButton from "../NextQuestionButton";
import CameraIcon from "../../ui/icons/CameraIcon";
import styled from "styled-components";
import Button from "../../ui/Button";
import idCardFront from "../../../images/faceImage.png";
import { Flex } from "../../ui/Flex";
import { useDispatch, useSelector } from "react-redux";
import { selectRegister, setCurrentQuestion, setFaceImage, setFaceUrl } from "../../../store/reducers/registerSlice";
import CaptureImage from "./CaptureImage";
import { base64ToFile } from "../../../utils/base64ToFile";
import EditImage from "./EditImage";
import { useTranslation } from "react-i18next";
import { useUploadFileMutation } from "../../../store/RTKQuery/upload/uploadFile";
import { questions } from "../Register";

const Question9FaceImageStyle = styled.div`
    h3.title {
        margin-bottom: 5px;
        font-weight: bolder;
    }

    .reference {
        color: #003399;
        font-weight: bold;
    }

    .idCardImage {
        width: 100px;
        height: 100px;
    }
`;

interface IProps {
}

const Question9FaceImage: FC<IProps> = () => {
  const dispatch = useDispatch();
  const { faceImage, faceUrl } = useSelector(selectRegister);
  const [isCaptureImageNow, setIsCaptureImageNow] = useState<Boolean>(false);
  const [isEditImageNow, setIsEditImageNow] = useState<Boolean>(false);
  const { t } = useTranslation();
  const [uploadImage, { data, isLoading, isSuccess }] =
    useUploadFileMutation();
  const nextPageNumber: number = 10;
  const goToNextPage = () => {
    dispatch(
      setCurrentQuestion(
        questions.filter((q) => q.page === nextPageNumber)[0]
      )
    );
  };
  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setFaceUrl(data.file_path));
      goToNextPage();
    }
  }, [isSuccess]);
  return (
    <Question9FaceImageStyle>
      <h3 className="title">
        {t("almostReadyCaptureSelfieAndLookStraightToTheCamera")}
      </h3>
      {!isCaptureImageNow && (
        <p className={"subtitle"}>
          {t("makeSureItMatchesTheDetailsYouProvidedEarlier")}
        </p>
      )}
      <Flex direction={"column"} alignItems={"center"}>
        {!faceImage.original ? (
          <>
            {!isCaptureImageNow ? (
              <>
                <button
                  className={"capture"}
                  type={"button"}
                  onClick={() => {
                    setIsCaptureImageNow(true);
                  }}
                >
                  <CameraIcon />
                </button>
                <p className={"captureWithPhoneCamera"}>
                  {t("captureWithPhoneCamera")}
                </p>
                {/* <p className="or">{t('or')}</p>
                                <Field
                                    name={'face_imageFormData'}
                                    text={t('uploadImage')}
                                    className={'uploadImage'}
                                    onChange={(
                                        event: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        if (event.currentTarget.files) {
                                            dispatch(
                                                setFaceImage({
                                                    original:
                                                        event.currentTarget
                                                            .files[0],
                                                    upload: event.currentTarget
                                                        .files[0],
                                                }),
                                            );
                                        }
                                    }}
                                    component={UploadFile}
                                />*/}
                <img
                  className={"idCardImage"}
                  src={idCardFront}
                  alt=""
                />
                <p className={"reference"}>{t("reference")}</p>
              </>
            ) : (
              <CaptureImage
                onCapture={(imageSrc) => {
                  dispatch(
                    setFaceImage({
                      original: base64ToFile(
                        imageSrc,
                        "FaceImage"
                      ),
                      upload: base64ToFile(
                        imageSrc,
                        "FaceImage"
                      )
                    })
                  );
                  setIsCaptureImageNow(false);
                }}
                onBack={() => {
                  setIsCaptureImageNow(false);
                }}
              />
            )}
          </>
        ) : (
          <>
            {isEditImageNow && faceImage.original ? (
              <EditImage
                onEdit={(imageSrc) => {
                  dispatch(
                    setFaceImage({
                      original: faceImage.original,
                      upload: base64ToFile(
                        imageSrc,
                        "FaceImage"
                      )
                    })
                  );
                  dispatch(setFaceUrl(""));
                  setIsEditImageNow(false);
                }}
                image={faceImage.original}
                onBack={() => {
                  setIsEditImageNow(false);
                }}
              />
            ) : (
              <>
                {faceImage.upload && (
                  <img
                    src={URL.createObjectURL(
                      faceImage.upload
                    )}
                    alt=""
                    className={"imagePreview"}
                  />
                )}
                {/*  <Flex
                                    alignItems={'center'}
                                    gap={10}
                                    className={'imageEditActions'}
                                    marginBottom={20}
                                >
                                    <button
                                        type={'button'}
                                        onClick={() => {
                                            setIsEditImageNow(true);
                                        }}
                                    >
                                        <CropIcon />
                                    </button>
                                    <button
                                        type={'button'}
                                        onClick={() => {
                                            setIsEditImageNow(true);
                                        }}
                                    >
                                        <RotateIcon />
                                    </button>
                                </Flex>*/}
                <div className={"uploadImageConfirmActions"}>
                  <Button
                    text={t("captureAgain") || ""}
                    variant={"outline"}
                    handleClick={() => {
                      dispatch(
                        setFaceImage({
                          original: null,
                          upload: null
                        })
                      );
                      dispatch(setFaceUrl(""));
                    }}
                  />
                  <NextQuestionButton
                    canNext={true}
                    nextPage={nextPageNumber}
                    text={t("yesContinue") || ""}
                    isLoading={isLoading}
                    onClick={() => {
                      if (!faceUrl) {
                        const formData = new FormData();
                        formData.append(
                          "file",
                          faceImage.upload as File
                        );
                        uploadImage(formData);
                      } else {
                        goToNextPage();
                      }
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Flex>
    </Question9FaceImageStyle>
  );
};
export default Question9FaceImage;
