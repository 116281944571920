import styled from 'styled-components';

const SelectFieldStyle = styled.div`
    width: 100%;

    label {
        margin-bottom: 10px;
    }

    .selectField__control {
        border-radius: 10px;
        border-color: #2f5496;

        &:hover {
            border-color: #2f5496;
        }

        .selectField__value-container {
            padding: 15px 20px;
        }

        .selectField__input-container {
            margin: 0;
            padding: 0;
        }

        &.selectField__control--is-focused {
            box-shadow: none;
        }

        .selectField__single-value {
            color: #2f5496;
        }
    }

    .selectField__menu {
        overflow: hidden;
        border-radius: 10px;

        .selectField__option {
            padding: 15px 20px;
        }

        .selectField__option--is-focused {
            background-color: #f1f1f1;

            &:hover {
                background-color: #f1f1f1;
            }
        }

        .selectField__option--is-selected {
            background-color: #00b0f0;

            &:hover {
                background-color: #00b0f0;
            }
        }

        .selectField__menu-list {
            padding: 0;
        }
    }

    .selectField__indicators {
        .selectField__indicator-separator {
            display: none;
        }

        .selectField__indicator {
            svg {
                path {
                    fill: #2f5496;
                }
            }
        }
    }
`;

export default SelectFieldStyle;
