import { FC } from "react";
import CitizenshipSelect from "../../ui/lookups/CitizenshipSelect";
import NextQuestionButton from "../NextQuestionButton";
import styled from "styled-components";
import InfoQuestionButton from "../InfoQuestionButton";
import { useTranslation } from "react-i18next";
import { useGetLookupsQuery } from "../../../store/RTKQuery/lookups/getLookups";
import { useSelector } from "react-redux";
import { selectUtility } from "../../../store/reducers/utilitySlice";

const Question1CitizenshipStyle = styled.div``;

interface IProps {
}

const Question1Citizenship: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectUtility);
  useGetLookupsQuery({
    lang: lang
  });
  return (
    <Question1CitizenshipStyle>
      <h3 className="title">
        {t("whatIsYourCitizenship")}
        <InfoQuestionButton />
      </h3>
      <div className="fields">
        <CitizenshipSelect
          label={t("citizenshipStar") || ""}
          placeholder={t("citizenship") || ""}
          name={"nationality"}
        />
      </div>
      <NextQuestionButton nextPage={2} canNext={true} />
      <p className="info">{t("questionInfo")}</p>
    </Question1CitizenshipStyle>
  );
};
export default Question1Citizenship;
